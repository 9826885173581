<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <div v-loading="loading">
      <Crumbs>
        <el-breadcrumb-item slot="after"> 绩效审核 </el-breadcrumb-item>
      </Crumbs>
      <div class="fullScreenMain">
        <!-- <div class="fullScreenMainHeader" style="color:#000;margin-bottom:10px;">
          <div>
            商务类型:
            <span style="display:inline-block;" class="bd_input">{{ value }}</span>
          </div>
        </div> -->
        <div class="fullScreenMainCon">
          <template v-if="businessInformation">
            <el-descriptions
              style="margin-bottom: 10px"
              title="商务基础信息"
              direction="vertical"
              :column="5"
              border>
              <el-descriptions-item label="商务类型">
                {{ businessInformation.businessType | dict(dictData.businessType) }}
              </el-descriptions-item>
              <!-- <el-descriptions-item label="商务支出类型">
                {{ businessInformation.disbursementType | dict(dictData.businessDisbursenentType) }}
              </el-descriptions-item> -->
              <el-descriptions-item label="项目来源">
                {{ businessInformation.projectSource | dict(dictData.projectSource) }}
              </el-descriptions-item>
              <el-descriptions-item label="实际商务负责人">
                {{ businessInformation.principalUserName | addDept(CompanyMembers) }}
              </el-descriptions-item>
              <!-- <el-descriptions-item
                v-if="
                  (businessInformation.disbursementType == 'I_THROUGH_RATIFICATION_IMPLEMENT' &&
                    businessInformation.externalPartnere == 'NOT') ||
                  (businessInformation.disbursementType == 'I_ASSIGN_IMPLEMENT' &&
                    businessInformation.externalPartnere == 'NOT')
                "
                label="商务牵头人">
                {{ businessInformation.leaderName | addDept(CompanyMembers) }}
              </el-descriptions-item>
              <el-descriptions-item
                v-if="
                  (businessInformation.disbursementType == 'I_THROUGH_RATIFICATION_IMPLEMENT' &&
                    businessInformation.externalPartnere == 'HAVE') ||
                  (businessInformation.disbursementType == 'I_ASSIGN_IMPLEMENT' &&
                    businessInformation.externalPartnere == 'HAVE')
                "
                label="商务经理">
                {{ businessInformation.businessManagerName | addDept(CompanyMembers) }}
              </el-descriptions-item>
              <el-descriptions-item
                v-if="
                  businessInformation.disbursementType == 'II_THROUGH_RATIFICATION_IMPLEMENT' &&
                  businessInformation.externalPartnere == 'HAVE'
                "
                label="商务牵头参与者">
                {{ businessInformation.leaderName | addDept(CompanyMembers) }}
              </el-descriptions-item>
              <el-descriptions-item
                v-if="
                  businessInformation.disbursementType == 'II_ASSIGN_IMPLEMENT' ||
                  (businessInformation.disbursementType == 'I_ASSIGN_IMPLEMENT' &&
                    businessInformation.externalPartnere == 'NOT')
                "
                label="指派人">
                {{ businessInformation.appointorName | addDept(CompanyMembers) }}
              </el-descriptions-item>
              <el-descriptions-item
                v-if="
                  businessInformation.disbursementType == 'INDEPENDENT_DEVELOPMENT' ||
                  (businessInformation.disbursementType == 'II_THROUGH_RATIFICATION_IMPLEMENT' &&
                    businessInformation.externalPartnere == 'NOT')
                "
                label="商务开展人">
                {{ businessInformation.promoterName | addDept(CompanyMembers) }}
              </el-descriptions-item> -->
            </el-descriptions>
          </template>
          <el-table :data="tableData[Index]" border style="width: 100%">
            <el-table-column align="center" type="index" label="序号" width="50"> </el-table-column>
            <el-table-column align="center" prop="workContent" label="工作内容" width="230">
            </el-table-column>
            <el-table-column
              align="center"
              prop="participatingDistributor"
              label="参与角色"
              width="250">
            </el-table-column>
            <el-table-column align="center" label="商务占比规则" width="190">
              <template slot-scope="scope">
                <el-tooltip effect="light" placement="top">
                  <div v-html="scope.row.ratioRuleDetail" slot="content" style="color: #000"></div>
                  <div>{{ scope.row.ratioRule }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- 无事前 -->
            <template v-if="Index == 0">
              <el-table-column align="center" label="商务占比(总比例100%或元)" width="180">
                <template slot-scope="scope">
                  <template>
                    <el-input class="userRation" v-model="scope.row.ratio" disabled> </el-input>
                  </template>
                </template>
              </el-table-column>
            </template>

            <!-- 新客户 -->
            <template v-if="Index == 1">
              <el-table-column align="center" label="商务占比(总比例100%)" width="180">
                <template slot-scope="scope">
                  <el-input class="userRation" v-model="scope.row.ratio" disabled> </el-input>
                </template>
              </el-table-column>
            </template>
            <!-- 老客户 -->
            <template v-if="Index == 2">
              <el-table-column align="center" label="商务占比(总比例100%)" width="180">
                <template slot-scope="scope">
                  <template>
                    <el-input class="userRation" v-model="scope.row.ratio" disabled> </el-input>
                  </template>
                </template>
              </el-table-column>
            </template>

            <el-table-column align="center" label="事项说明">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.instructionMatters"
                  class="userRation"
                  disabled
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 99 }"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="人员占比(当前人员总占比不能超过商务占比%)"
              width="320">
              <template slot-scope="scope">
                <template v-for="(i, j) in scope.row.userAssignment">
                  <template v-if="i.workContent == scope.row.workContent">
                    <div :key="j">
                      <div class="userProportion">
                        <span>成员:{{ i.userName }}</span
                        ><span
                          >占比:<el-input
                            disabled
                            style="width: 80px"
                            oninput="value=value.replace(/[^\d.]/g,'')"
                            v-model="i.userRatio"></el-input>
                        </span>
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </el-table-column>
          </el-table>
          <template v-if="opinionPerformanceRecordList.length > 0">
            <h3 style="margin-top: 20px">意见区:</h3>
            <div class="text-center">
              <template v-for="i in opinionPerformanceRecordList">
                <div class="text-center-top" :key="i.id">
                  <span class="text-center-top-left">
                    <el-button type="success" class="text-btn" disabled v-if="i.isCheckPass === '1'"
                      >通过</el-button
                    >
                    <el-button type="info" class="text-btn" disabled v-else>不通过</el-button>
                    <span class="opinion">审批意见: {{ i.opinion }}</span>
                  </span>
                  <span class="text-center-top-right">
                    <span class="name">处理人: {{ i.auditUserName }}</span>
                    <span class="day">审批时间: {{ i.auditDate | timeFormat }}</span>
                  </span>
                </div>
              </template>
            </div>
          </template>
          <template
            v-if="
              isbusinessManage.performanceStatus == 1 || isbusinessManage.performanceStatus == 2
            ">
            <div class="text-center">
              <el-dialog
                title="意见区"
                :visible.sync="dialogFormVisible"
                :before-close="cancelFn"
                :append-to-body="true"
                width="30%">
                <el-form :model="form" :rules="rules" ref="form">
                  <el-form-item label="审批意见" prop="opinion">
                    <el-input v-model="form.opinion" autocomplete="off" type="textarea"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="cancelFn">取 消</el-button>
                  <el-button type="primary" @click="addFn(2)">确 定</el-button>
                </div>
              </el-dialog>
              <div class="distribution-btm-btn btm-Btn"></div>
            </div>
          </template>
        </div>

        <div class="fullScreenOperation" v-if="!loading">
          <template
            v-if="
              (isbusinessManage.performanceStatus == 1 ||
                isbusinessManage.performanceStatus == 2) &&
              index == 2
            ">
            <el-button type="success" @click="addFn(1)">通过</el-button>
            <el-button type="danger" @click="dialogFormVisible = true">不通过</el-button>
          </template>
          <el-button class="return" type="info" @click="returnFn">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  props: {
    showEditDialog: {
      type: Boolean,
      default: true,
    },
    ID: {
      type: [String, Number],
      default: '',
    },
    isManage: {
      //是否商务填报进来
      type: Boolean,
      default: false,
    },
    index: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      opinionInfo: {
        businessId: '',
        businessPerformanceRequstList: [
          {
            businessType: '',
            id: '',
            ratio: '',
            ratioRule: '',
            ratioRuleDetail: '',
            workContent: '',
            instructionMatters: '',
            userAssignment: [],
          },
        ],
        isCheck: '',
        opinionRecordRequst: {
          id: '',
          isCheckPass: '',
          opinion: '',
        },
        status: '',
      },
      form: {
        opinion: '',
      },
      dialogFormVisible: false,
      // name: "",
      value: '',
      Index: 0,
      tableData: [
        [
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '提供有效项目信息',
            ratioRule: '100元或1%',
            percentage: [0, 1],
            ratioRuleDetail:
              '（1）若为I类项目，如信息提供人为非商务部专职商务（招标）信息收集人员所提供的有效商务信息，则给予其1%（如低于200元则按200元）绩效；<br/>如为商务部专职商务（招标）信息收集人员所提供，则给予其100元绩效。<br/>（2）若为其他类项目，则从三方面考察确定，即根据项目信息获取时间，<br/>如为该项目最早获知的给予其1%绩效，否则0.5%绩效；<br/>根据项目信息丰富程度，如包含项目牵头人、项目金额、项目选取方式等信息的给予其1%绩效，否则0.5%绩效；根据项目产生形式，<br/>如项目为省级以上政府部门公开部署的则不给予绩效，<br/>如项目为市级政府部门公开部署的给予其0.5%绩效，其他给予其1%绩效。<br/>如低于200元则按照200元计算',
            participatingDistributor: '信息提供人',
            ratio: '',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '牵头沟通',
            ratioRule: '0%~30%',
            percentage: [0, 30],
            participatingDistributor: '商务牵头人',
            ratio: '',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
            ratioRuleDetail:
              '（1）若为I类项目，如事中沟通获得了业主方重要支持的给予其30%绩效；<br/>如事中沟通获取了有效信息支撑竞标的给予其15%绩效。<br/>（2）其它类项目，则根据承接方排他性和采购方式分别给予其绩效。<br/>根据承接方排他性，如为确定性选择对象的则给予其47%绩效；如为有限竞争性选择对象则给予其25%绩效。<br/>根据采购方式，如直接选取的则给予其10%绩效；<br/>如通过询价选取的则给予8%绩效；<br/>通过竞争性磋商或第三方采购机构选择的则给予其5%绩效；<br/>通过政府公共采购平台选择的不给予其绩效。',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '投标文件技术方案编制',
            ratioRule: '0%~30%',
            percentage: [0, 30],
            participatingDistributor: '投标技术方案编制部门',
            ratio: '',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'BIDDING_SCHEME',
            ratioRuleDetail:
              '（1）评估售前方案要求程度，如要求提供售前方案（针对性技术方案、咨询、DEMO等）的，按常规水平1个“人天工作量/每百万项目金额”（不足100万按百万计）增加牵头沟通绩效的3%，<br/>如为确定性选择对象的，直至牵头沟通绩效的30%封顶，<br/>如为有限竞争性选择的50%封顶，给予售前方案参与部门；<br/>仅为报价方案的，则给予报价方案编制部门200元绩效。<br/>（2）结合售前方案及采购要求评估，如要求售前方案的则投标技术文件由售前方案部门负责并按前一款规定分配绩效。<br/>如没有要求售前方案的，则公开招标或竞争性谈判其技术方案占重要作用的（技术分超过35分）给予有关部门牵头沟通绩效的30%；<br/>公开招标或竞争性谈判但以价格和商务为主的（技术分不超过35分）给予有关部门牵头沟通绩效的15%；竞争性磋商等需要简单技术方案的给予有关部门牵头沟通绩效的5%；<br/>询价等仅需提供报价方案的，则给予有关部门200元绩效；其它不需要技术方案的不给予绩效',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '商务跟进',
            ratioRule: '0%~15%',
            percentage: [0, 15],
            participatingDistributor: '商务经理',
            ratio: '',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
            ratioRuleDetail:
              '商务经理指协助商务牵头人开展日常商务跟踪的公司员工，根据与商务牵头人的工作分工，由商务审核人给予其相应绩效。如商务牵头人为外部商务合作者，则给予其15%绩效；<br/>如与商务牵头人为同一人或没有商务牵头人的，则给予其10%绩效；<br/>如与商务牵头人为不同人的，则根据商务经理商务跟进工作量的情况，按照总额10%分别给予商务经理和商务牵头人相应绩效',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '其他',
            participatingDistributor: '公司',
            ratio: '',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRule: '剩余比例',
            percentage: [],
            ratioRuleDetail: '',
          },
        ],
        [
          {
            businessType: '有事前沟通新客户项目',
            workContent: '提供有效项目信息',
            ratioRule: '1%~3%',
            percentage: [1, 3],
            ratio: '',
            participatingDistributor: '信息提供人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
            ratioRuleDetail:
              '（1）若为I类项目，如信息提供人为非商务部专职商务（招标）信息收集人员所提供的有效商务信息，则给予其1%（如低于200元则按200元）绩效；<br/>如为商务部专职商务（招标）信息收集人员所提供，则给予其100元绩效。<br/>（2）若为其他类项目，则从三方面考察确定，即根据项目信息获取时间，<br/>如为该项目最早获知的给予其1%绩效，否则0.5%绩效；<br/>根据项目信息丰富程度，如包含项目牵头人、项目金额、项目选取方式等信息的给予其1%绩效，否则0.5%绩效；根据项目产生形式，<br/>如项目为省级以上政府部门公开部署的则不给予绩效，<br/>如项目为市级政府部门公开部署的给予其0.5%绩效，其他给予其1%绩效。<br/>如低于200元则按照200元计算',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '牵头沟通',
            ratioRule: '0%~57%',
            percentage: [0, 57],
            ratio: '',
            participatingDistributor: '商务牵头人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
            ratioRuleDetail:
              '（1）若为I类项目，如事中沟通获得了业主方重要支持的给予其30%绩效；<br/>如事中沟通获取了有效信息支撑竞标的给予其15%绩效。<br/>（2）其它类项目，则根据承接方排他性和采购方式分别给予其绩效。<br/>根据承接方排他性，如为确定性选择对象的则给予其47%绩效；如为有限竞争性选择对象则给予其25%绩效。<br/>根据采购方式，如直接选取的则给予其10%绩效；<br/>如通过询价选取的则给予8%绩效；<br/>通过竞争性磋商或第三方采购机构选择的则给予其5%绩效；<br/>通过政府公共采购平台选择的不给予其绩效。',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '售前咨询与投标技术方案编制',
            ratioRule: '牵头沟通绩效的0%~50%',
            percentage: [0, 50],
            ratio: '',
            participatingDistributor: '售前咨询与投标技术方案编制部门',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'CONSULT_BIDDING_SCHEME',
            ratioRuleDetail:
              '（1）评估售前方案要求程度，如要求提供售前方案（针对性技术方案、咨询、DEMO等）的，按常规水平1个“人天工作量/每百万项目金额”（不足100万按百万计）增加牵头沟通绩效的3%，<br/>如为确定性选择对象的，直至牵头沟通绩效的30%封顶，<br/>如为有限竞争性选择的50%封顶，给予售前方案参与部门；<br/>仅为报价方案的，则给予报价方案编制部门200元绩效。<br/>（2）结合售前方案及采购要求评估，如要求售前方案的则投标技术文件由售前方案部门负责并按前一款规定分配绩效。<br/>如没有要求售前方案的，则公开招标或竞争性谈判其技术方案占重要作用的（技术分超过35分）给予有关部门牵头沟通绩效的30%；<br/>公开招标或竞争性谈判但以价格和商务为主的（技术分不超过35分）给予有关部门牵头沟通绩效的15%；竞争性磋商等需要简单技术方案的给予有关部门牵头沟通绩效的5%；<br/>询价等仅需提供报价方案的，则给予有关部门200元绩效；其它不需要技术方案的不给予绩效',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '商务跟进',
            ratio: '',
            participatingDistributor: '商务经理',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
            ratioRule: '0%~15%',
            percentage: [0, 15],
            ratioRuleDetail:
              '商务经理指协助商务牵头人开展日常商务跟踪的公司员工，根据与商务牵头人的工作分工，由商务审核人给予其相应绩效。如商务牵头人为外部商务合作者，则给予其15%绩效；<br/>如与商务牵头人为同一人或没有商务牵头人的，则给予其10%绩效；<br/>如与商务牵头人为不同人的，则根据商务经理商务跟进工作量的情况，按照总额10%分别给予商务经理和商务牵头人相应绩效',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '有效引荐',
            ratioRule: '0%~15%',
            percentage: [0, 15],
            ratio: '',
            participatingDistributor: '引荐人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'REFERRER',
            ratioRuleDetail:
              '（1）引荐到业主方关键决策人员的给予其15%绩效；<br>引荐到项目重要执行人员的给予其10%绩效；引荐到项目一般人员的给予其5%绩效。<br>II、III类项目如果没有其他引荐人的，则商务牵头人视为引荐人，给予其15%绩效。<br>（2）同时引荐到多名业主方人员的情况，按照业主方最高作用人员的情况确定绩效，如其他业主方人员也发挥一定作用的，视情况调剂有关引荐人一定绩效。公司多名员工引荐到业主方相关人员的，根据发挥作用的情况对相应绩效进行分配',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '其他',
            ratio: '',
            participatingDistributor: '公司',
            instructionMatters: '',
            percentage: [],
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRule: '剩余比例',
            ratioRuleDetail: '',
          },
        ],
        [
          {
            businessType: '有事前沟通老客户项目',
            workContent: '提供有效项目信息',
            ratioRule: ' 1%~3%',
            percentage: [1, 3],
            ratio: '',
            participatingDistributor: '信息提供人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
            ratioRuleDetail:
              '（1）若为I类项目，如信息提供人为非商务部专职商务（招标）信息收集人员所提供的有效商务信息，则给予其1%（如低于200元则按200元）绩效；<br/>如为商务部专职商务（招标）信息收集人员所提供，则给予其100元绩效。<br/>（2）若为其他类项目，则从三方面考察确定，即根据项目信息获取时间，<br/>如为该项目最早获知的给予其1%绩效，否则0.5%绩效；<br/>根据项目信息丰富程度，如包含项目牵头人、项目金额、项目选取方式等信息的给予其1%绩效，否则0.5%绩效；根据项目产生形式，<br/>如项目为省级以上政府部门公开部署的则不给予绩效，<br/>如项目为市级政府部门公开部署的给予其0.5%绩效，其他给予其1%绩效。<br/>如低于200元则按照200元计算',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '牵头沟通',
            ratioRule: '0%~57%',
            percentage: [0, 57],
            ratio: '',
            participatingDistributor: '商务牵头人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
            ratioRuleDetail:
              '（1）若为I类项目，如事中沟通获得了业主方重要支持的给予其30%绩效；<br/>如事中沟通获取了有效信息支撑竞标的给予其15%绩效。<br/>（2）其它类项目，则根据承接方排他性和采购方式分别给予其绩效。<br/>根据承接方排他性，如为确定性选择对象的则给予其47%绩效；如为有限竞争性选择对象则给予其25%绩效。<br/>根据采购方式，如直接选取的则给予其10%绩效；<br/>如通过询价选取的则给予8%绩效；<br/>通过竞争性磋商或第三方采购机构选择的则给予其5%绩效；<br/>通过政府公共采购平台选择的不给予其绩效。',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '售前咨询与投标技术方案编制',
            ratioRule: '牵头沟通绩效的0%~50%',
            percentage: [0, 50],
            ratio: '',
            participatingDistributor: '售前咨询与投标技术方案编制部门',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'CONSULT_BIDDING_SCHEME',
            ratioRuleDetail:
              '（1）评估售前方案要求程度，如要求提供售前方案（针对性技术方案、咨询、DEMO等）的，按常规水平1个“人天工作量/每百万项目金额”（不足100万按百万计）增加牵头沟通绩效的3%，<br/>如为确定性选择对象的，直至牵头沟通绩效的30%封顶，<br/>如为有限竞争性选择的50%封顶，给予售前方案参与部门；<br/>仅为报价方案的，则给予报价方案编制部门200元绩效。<br/>（2）结合售前方案及采购要求评估，如要求售前方案的则投标技术文件由售前方案部门负责并按前一款规定分配绩效。<br/>如没有要求售前方案的，则公开招标或竞争性谈判其技术方案占重要作用的（技术分超过35分）给予有关部门牵头沟通绩效的30%；<br/>公开招标或竞争性谈判但以价格和商务为主的（技术分不超过35分）给予有关部门牵头沟通绩效的15%；竞争性磋商等需要简单技术方案的给予有关部门牵头沟通绩效的5%；<br/>询价等仅需提供报价方案的，则给予有关部门200元绩效；其它不需要技术方案的不给予绩效',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '商务跟进',
            ratioRule: '0%~15%',
            percentage: [0, 15],
            ratio: '',
            participatingDistributor: '商务经理',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
            ratioRuleDetail:
              '商务经理指协助商务牵头人开展日常商务跟踪的公司员工，根据与商务牵头人的工作分工，由商务审核人给予其相应绩效。如商务牵头人为外部商务合作者，则给予其15%绩效；<br/>如与商务牵头人为同一人或没有商务牵头人的，则给予其10%绩效；<br/>如与商务牵头人为不同人的，则根据商务经理商务跟进工作量的情况，按照总额10%分别给予商务经理和商务牵头人相应绩效',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '历史商务关系或有效引荐',
            ratioRule: '0%~15%',
            percentage: [0, 15],
            ratio: '',
            participatingDistributor: '引荐人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'HISTORICAL_BUSINESS_REFERRER',
            ratioRuleDetail:
              '（1）引荐到业主方关键决策人员的给予其15%绩效；<br>引荐到项目重要执行人员的给予其10%绩效；引荐到项目一般人员的给予其5%绩效。<br>II、III类项目如果没有其他引荐人的，则商务牵头人视为引荐人，给予其15%绩效。<br>（2）同时引荐到多名业主方人员的情况，按照业主方最高作用人员的情况确定绩效，如其他业主方人员也发挥一定作用的，视情况调剂有关引荐人一定绩效。公司多名员工引荐到业主方相关人员的，根据发挥作用的情况对相应绩效进行分配',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '上一项目实施团队',
            ratioRule: '牵头沟通绩效的0%~30%',
            percentage: [0, 30],
            ratio: '',
            participatingDistributor: '驻场团队或原实施团队',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROJECT_IMPLEMENTATION',
            ratioRuleDetail:
              '（1）对于驻场项目服务单位委托的新项目。项目关键决策者或重要执行者与驻场团队负责人进行项目决定性沟通的，则驻场团队视为牵头沟通人员，给予团队相应绩效；<br/>驻场单位通过驻场人员向公司提供项目信息的，驻场团队在获得提供有效项目信息费用的基础上，分配牵头沟通费用的30%绩效；<br/>驻场单位直接与公司非驻场人员联系并提供项目信息，同时对驻场团队持较为肯定态度的，分配牵头沟通费用的20%绩效，<br/>如不持负面态度的，分配牵头沟通费用的10%绩效，持负面态度的，不分配绩效。<br/>（2）对于非驻场项目服务单位委托的新项目。项目关键决策者或重要执行者与项目实施人员进行项目决定性沟通的，则实施部门视为牵头沟通人员，给予团队相应绩效；<br/>项目单位通过实施人员向公司提供项目信息的，实施团队在获得提供有效项目信息费用的基础上，各分配实施团队和生产部门牵头沟通绩效的10%；<br/>服务单位直接与公司非实施人员联系并提供项目信息，同时对上一项目实施团队持肯定态度的，分配生产部门牵头沟通绩效的10%。',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '其他',
            ratioRule: '剩余比例',
            percentage: [],
            ratio: '',
            participatingDistributor: '公司',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRuleDetail: '',
          },
        ],
      ],
      opinionPerformanceRecordList: [],
      rules: {
        opinion: [
          {
            required: true,
            message: '请输入不通过的意见内容',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 500,
            message: '意见内容在0~500个字符',
            trigger: 'blur',
          },
        ],
      },
      businessManage: [],
      isbusinessManage: [],
      dictData: {
        businessType: [],
        businessDisbursenentType: [],
        projectSource: [],
      },
    }
  },
  created() {
    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_DISBURSEMENT_TYPE', true)
      .then(res => {
        this.dictData.businessDisbursenentType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    // 避免用户进来直接去商务绩效查看，获取不到部门前缀
    if (this.CompanyMembers && this.CompanyMembers.length == 0) {
      this.$api.user
        .staffAllList({})
        .then(res => {
          this.$store.commit({
            type: 'business/SET_COMPANY_MEBERS',
            CompanyMembers: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.dictData.projectSource = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  watch: {
    businessManage: {
      deep: true,
      immediate: true,
      handler(val, oldval) {
        if (val.businessManage) {
          let arr = val.businessManage
          if (arr.businessType === 'WSQGTJBLXM') {
            this.value = '无事前沟通竞标类项目'
            this.Index = 0
            this.dataProcessing(this.Index, val.businessPerformanceList, val.userAssignmentMap)
            return
          } else if (arr.businessType === 'YSQGTXKHXM') {
            this.value = '有事前沟通新客户项目'
            this.Index = 1
            this.dataProcessing(this.Index, val.businessPerformanceList, val.userAssignmentMap)
            return
          } else if (arr.businessType === 'YSQGTLKHXM') {
            this.value = '有事前沟通老客户项目'
            this.Index = 2
            this.dataProcessing(this.Index, val.businessPerformanceList, val.userAssignmentMap)
            return
          }
        }
      },
    },
    ID: {
      deep: true,
      immediate: true,
      handler(val, oldval) {
        this.getData()
      },
    },
  },
  computed: {
    ...mapState({
      businessInformation: state => state.business.businessInformation,
      userAssignmentMap: state => state.business.userAssignmentMap,
      CompanyMembers: state => state.business.CompanyMembers,
    }),
  },
  filters: {
    // 添加部门前缀
    addDept(val, n) {
      let name = ''
      if (n && n.length > 0) {
        for (let index = 0; index < n.length; index++) {
          const v = n[index]
          if (v.userName == val) {
            var newArr = v.departmentName.split(',')
            name = v.userName + '-' + newArr[0]
            break
          }
        }
      } else {
        name = val
      }
      return name
    },
  },
  mounted() {},
  methods: {
    async getData() {
      if (this.ID) {
        this.loading = true
        const id = this.ID
        this.opinionPerformanceRecordList = []
        this.loading = true
        const res = await this.$api.businessPerformance.getPerformanceInfo(id)
        this.loading = false
        if (res.data?.businessManage) {
          this.businessManage = res.data
          this.$store.commit({
            type: 'business/SET_BUSINESS_INFORMATION',
            businessInformation: res.data.businessManage,
          })
          this.$store.commit({
            type: 'business/SET_USER_ASSIGNMENT_MAP',
            userAssignmentMap: res.data.userAssignmentMap,
          })
        }
        if (res.data.businessManage) {
          this.isbusinessManage = res.data.businessManage
        }
        if (res.data.opinionPerformanceRecordList.length > 0) {
          this.opinionPerformanceRecordList = res.data.opinionPerformanceRecordList
        }
        this.loading = false
      }
    },
    dataProcessing(a, b, c) {
      this.tableData[a].forEach((v, i) => {
        b.forEach((k, j) => {
          if (i === j) {
            v.businessType = k.businessType
            v.workContent = k.workContent
            v.ratioRule = k.ratioRule
            v.ratio = k.ratio
            v.id = k.id
            v.instructionMatters = k.instructionMatters
          }
        })
      })
      if (c) {
        if (c.userAssignment != null) {
          this.tableData[a].forEach((v, i) => {
            c.userAssignment.forEach(k => {
              if (v.workContent == k.workContent) {
                v.userAssignment.push(k)
              }
            })
          })
        }
      }
    },
    async addFn(index) {
      let arr = this.tableData[this.Index]
      arr.forEach(v => {
        v.ratioRuleDetail = ''
      })
      this.opinionInfo.businessPerformanceRequstList = arr
      this.opinionInfo.businessId = this.ID
      this.opinionInfo.isCheck = index
      this.opinionInfo.opinionRecordRequst.isCheckPass = index
      if (index === 1) {
        this.opinionInfo.opinionRecordRequst.opinion = '同意'
      } else {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.opinionInfo.opinionRecordRequst.opinion = this.form.opinion
            this.dialogFormVisible = false
          }
        })
      }
      const res = await this.$api.businessPerformance.updateAudit(this.opinionInfo)
      if (index === 1) {
        Message.success('审批通过')
      } else {
        Message.error('审批不通过')
      }
      this.returnFn()
    },
    cancelFn() {
      this.opinion = ''
      this.dialogFormVisible = false
      this.$refs.form.resetFields()
    },

    returnFn() {
      this.$emit('update:showEditDialog', false)
      this.$emit('updateData')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/config.scss';
.fullScreen {
  display: block;

  .userProportion {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    margin-bottom: 3px;
  }

  .text-center {
    width: 100%;
    height: 300px;
    //   border: 1px solid #000;
    overflow: auto;
    padding: 16px;
    .text-center-top {
      margin-bottom: 10px;
      color: #000;
      background-color: #f3f3f5;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text-center-top-left {
        // width: 50%;
        flex: 1;
        display: flex;
        align-items: center;
        .text-btn {
          height: 48px;
          width: 90px;
          margin-right: 20px;
        }
        .opinion {
          display: inline-block;
          margin-left: 8px;
          width: 100%;
          word-break: break-word;
          padding: 5px 0;
          font-family: PingFang SC;
          font-weight: 600;
          // font-size: 18px;
        }
      }
      .text-center-top-right {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        .day {
          margin-left: 10%;
          padding-right: 8px;
        }
      }
    }
  }
}
.bd_input {
  border: 1px solid #ccc;
  height: 40px;
  line-height: 40px;
  border-radius: 5%;
  padding: 0 8px;
  text-align: center;
}
/deep/.el-loading-spinner {
  margin-top: 21%;
}
</style>
